<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Assessment Schedule Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="d-flex">
                <label class="col-form-label me-1">Program Name</label>
                <div style="width: 150px">
                  <v-select
                    label="short_name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="(options) => options.id != program.id"
                    @option:selected="
                      getFilterData(program, batch, type, level)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Batch</label>
                <div style="width: 150px">
                  <v-select
                    label="batch_no"
                    v-model="batch"
                    :options="batches"
                    :clearable="false"
                    :selectable="(options) => options.id != batch.id"
                    @option:selected="
                      getFilterData(program, batch, type, level)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Assessment Type</label>
                <div style="width: 150px">
                  <v-select
                    v-model="type"
                    label="name"
                    :options="types"
                    :reduce="(name) => name.name"
                    :clearable="false"
                    :selectable="(options) => options.name != type.name"
                    @option:selected="
                      getFilterData(program, batch, type, level)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Level</label>
                <div style="width: 150px">
                  <v-select
                    v-model="level"
                    label="name"
                    :options="levels"
                    :selectable="(options) => options != level"
                    :clearable="false"
                    @option:selected="
                      getFilterData(program, batch, type, level)
                    "
                  >
                  </v-select>
                </div>

                <div @click="refreshData" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="assessment-schedule-list-report"
                style="width: 1600px; margin-right: 1.2em"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No</th>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Program Name</th>
                    <th>Level</th>
                    <th>Batch</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Assessor Name</th>
                    <th>Assessment Type</th>
                    <th>Assessment Location</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in allScheduleList" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.employee_id }}</td>
                    <td>{{ item.employee_name }}</td>
                    <td>{{ item.program_name }}</td>
                    <td>{{ item.level }}</td>
                    <td>{{ item.batch }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.time }}</td>
                    <td>{{ item.assessor_name }}</td>
                    <td>{{ item.assessment_type }}</td>
                    <td>{{ item.assessment_location }}</td>
                    <td>{{ item.remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { months } from "../../../utlis/months";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
  },
  data() {
    return {
      programs: [],
      batches: [],
      levels: [],
      types: [
        {
          id: 1,
          name: "Orientation",
        },
        {
          id: 2,
          name: "InClass",
        },
        {
          id: 3,
          name: "OJT",
        },
      ],
      program: "",
      batch: "",
      level: "",
      type: "",
      allScheduleList: [],
      title: "Assessment Schedule Report Lists",
      headers: [
        "No.",
        "Employee ID",
        "Employee Name",
        "Program Name",
        "Level",
        "Batch",
        "Date",
        "Time",
        "Assessor Name",
        "Assessment Type",
        "Assessment Location",
        "Remark",
      ],
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },

  methods: {
    async getPrograms() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getBatch() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/program-batches/list`)
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getAllLevels() {
      this.loading = true;
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
      });
    },
    getFilterData(program, batch, type, level) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        // console.log(settings, dataIndex);
        let programFilter = program?.name;
        let programStage = data[3];
        let batchFilter = batch?.batch_no;
        let batchStage = data[5];
        let typeFilter = type;
        let typeStage = data[9];
        let levelFilter = level?.name;
        let levelStage = data[4];
        if (
          (programFilter == programStage || !programFilter) &&
          (batchFilter == batchStage || !batchFilter) &&
          (typeFilter == typeStage || !typeFilter) &&
          (levelFilter == levelStage || !levelFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#assessment-schedule-list-report").DataTable().draw();
    },

    async getAllScheduleList() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-schedule-report`)
        .then((response) => {
          const data = response.data.data;
          data.forEach((item) => {
            this.allScheduleList.push({
              id: item.id,
              employee_id: item.employee_id,
              employee_name: item.employee_name,
              program_name: item.assessment.program_name,
              level: item.assessment.level,
              batch: item.assessment.program_batch_no,
              date: item.date,
              time: item.time,
              assessor_name: item.assessor_employee_name,
              assessment_type:
                item.assessment.type == "ORI"
                  ? "Orientation"
                  : item.assessment.type,
              assessment_location: item.assessment_location,
              remark: item.remark,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          this.toast.error("Something went wrong.");
        });
      this.loading = false;
      this.$Progress.finish();
      $("#assessment-schedule-list-report").DataTable().draw();
    },
    refreshData() {
      this.program = "";
      this.batch = "";
      this.level = "";
      this.type = "";
      this.allScheduleList = [];
      this.clearFilter();
      this.getAllScheduleList();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#assessment-schedule-list-report").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#assessment-schedule-list-report")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#assessment-schedule-list-report")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      exportExcel(sheetData, "Assessment Schedule");
      this.excelLoading = false;
    },
  },
  created() {
    this.clearFilter();
    this.getPrograms();
    this.getBatch();
    this.getAllLevels();
    this.getAllScheduleList();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
